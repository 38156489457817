import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import registrations from './routes/registrations'
import pedagogic from './routes/pedagogic'
import audiovisual from './routes/audiovisual'
import moderator from './routes/moderator'

import softphone from './routes/softphone'
import authetication from './routes/authetication'
import defaults from './routes/defaults'
import uptos3 from './routes/uptos3'
import backoffice from './routes/backoffice'
import authUser from './routes/auth-user'
import dialer from './routes/dialer'
import reports from './routes/reports'
import pabx from './routes/pabx'
import monitoring from './routes/monitoring'
import appointmentnew from './routes/appointment-new'
import appointment from './routes/appointment'
import dashboard from './routes/dashboard'
import accounts from './routes/accounts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...accounts,
    ...dashboard,
    ...appointmentnew,
    ...appointment,
    ...registrations,
    ...pedagogic,
    ...audiovisual,
    ...moderator,
    ...authetication,
    ...defaults,
    ...uptos3,
    ...softphone,
    ...authUser,
    ...dialer,
    ...pabx,
    ...reports,
    ...backoffice,
    ...monitoring,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta && !to.meta.notauthentication) {
    if (!localStorage.getItem("userData")) {
      return next({ name: "auth-login" });
    }
  }
  return next();
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router